import classNames from "classnames";
import get from "lodash/get";
import Link from "next/link";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { useCookies } from "react-cookie";

import BreakTheLoveAPI from "@/api/breakthelove/facilities";
import FormattedHeadline from "@/components/FormattedHeadline";
import JoinFlowCTA from "@/components/JoinFlowCTA";
import AuthWrapper from "@/components/auth-wrapper";
import FormattedDescription from "@/components/description/formatted-description";
import LinkWithArrow from "@/components/links/link-with-arrow-kam";
import { LEAD_FLOW_CTA_SOURCE } from "@/components/seo-and-analytics/adobe-data-layer/acdl-data-layer/lead-flow";
import { COOKIES, ROUTES } from "@/constants";
import { DigitalDataLayerContext } from "@/context/DigitalDataLayer";
import { UserContext } from "@/context/User";

import defaultStyles from "./EditorialTitle.module.scss";

const EditorialTitle = ({ data, styles, children, clubType, isThemeLight }) => {
  const { query } = useRouter();
  const { fields } = data;
  const {
    headline,
    headlineMultiline,
    subcopy,
    linkWithArrow,
    button,
    logo,
    label,
    imageMobile,
    imageDesktop,
  } = fields;
  const imageLogo = get(logo, "fields.imageFile.fields", null);
  const imageDesktopField = get(imageDesktop, "fields.imageFile.fields", null);
  const imageMobileField = get(imageMobile, "fields.imageFile.fields", null);
  const imageUrl = get(imageLogo, "file.url", null);
  const altText = get(imageLogo, "description", "");
  const title = get(imageLogo, "title", "");
  const moduleStyles = { ...defaultStyles, ...styles };
  const imageDesktopTitle = get(imageDesktop, "fields.imageTitle");
  const editorialTitleStylesOverrideClass = moduleStyles.editorialTitleOverride
    ? moduleStyles.editorialTitleOverride
    : "";

  const { setLeadFlowInfo } = useContext(DigitalDataLayerContext);

  const handleClick = (leadFlow, text) => {
    setLeadFlowInfo({
      ctaEvent: `${text} | ${LEAD_FLOW_CTA_SOURCE.BODY}`,
      data: leadFlow.fields,
      isEmbedded: false,
    });
  };

  const { session } = useContext(UserContext);

  const [cookies] = useCookies([COOKIES.USER_SESSION_ACCESS_TOKEN.name]);
  const hasUserSession = !!cookies[COOKIES.USER_SESSION_ACCESS_TOKEN.name];

  const handleRedirect = (response) => {
    window.location.href = get(response, "ott")
      ? `${
          process.env.NEXT_PUBLIC_BTL_APP_HOST
        }/equinox-hamptons?ott=${encodeURIComponent(get(response, "ott"))}`
      : `${process.env.NEXT_PUBLIC_BTL_APP_HOST}/equinox-hamptons`;
  };

  const handleOnClick = async () => {
    let payload = {};

    if (query.memberToken) {
      payload = {
        memberId: atob(query.memberToken),
      };
    } else {
      if (session?.user) {
        const {
          user: {
            firstName,
            lastName,
            email,
            country,
            state,
            zip,
            homeFacilityId,
            memberId,
            hamptonsAgreementStatusId,
            hasHamptonsAddOnAgreement,
          },
        } = session;

        payload = {
          clubId: homeFacilityId,
          country,
          email,
          firstName,
          hamptonsAgreementStatusId,
          hasHamptonsAddOnAgreement,
          lastName,
          memberId,
          state,
          zip,
        };
      }
    }

    await BreakTheLoveAPI.getAuthToken(payload)
      .then((response) => {
        handleRedirect(response);
      })
      .catch(() => {
        handleRedirect();
      });
  };

  let buttonComp;

  //Check to see if it is a normal link or triggers a schedule a visit lead flow
  if (
    button &&
    button.sys.contentType.sys.id === "componentLinkTriggerLeadFlow"
  ) {
    const {
      fields: { text, leadFlow },
    } = button;

    buttonComp = (
      <AuthWrapper renderOnUserSession={false}>
        <Link href={ROUTES.NEW_LEAD_FLOW_ROUTE}>
          <a
            className={`${moduleStyles.button} ${
              isThemeLight
                ? defaultStyles.blackBorder
                : defaultStyles.whiteBorder
            }`}
            onClick={() => handleClick(leadFlow, text)}
            role="button"
          >
            {text}
          </a>
        </Link>
      </AuthWrapper>
    );
  } else if (button && button.sys.contentType.sys.id === "componentLink") {
    if (button.fields && button.fields.isJoinFlow) {
      buttonComp = (
        <JoinFlowCTA
          className={`${moduleStyles.button} ${
            isThemeLight ? defaultStyles.blackBorder : defaultStyles.whiteBorder
          }`}
          data-is-long={button.fields.text.length > 25}
          eventSource={LEAD_FLOW_CTA_SOURCE.BODY}
          label={button.fields.text}
          originalUrl={button.fields.url}
        />
      );
    } else if (!hasUserSession) {
      buttonComp = (
        <a
          className={`${moduleStyles.button} ${
            isThemeLight ? defaultStyles.blackBorder : defaultStyles.whiteBorder
          }`}
          data-is-long={button.fields.text.length > 25}
          href={`${button.fields.url}${
            query.token ? `?token=${query.token}` : ""
          }`}
          title={button.fields.text}
        >
          {button.fields.text}
        </a>
      );
    }
  } else if (data.fields.nonUiTitle.toLowerCase().includes("break the love")) {
    buttonComp = (
      <button
        className={`${moduleStyles.button} ${defaultStyles.whiteBorder}`}
        name="Editorialtitle - Reserve Court"
        onClick={handleOnClick}
        style={{ backgroundColor: "black", color: "white" }}
        title={data.fields.title}
      >
        Reserve Court
      </button>
    );
  }

  const updatedStyles = {};

  if (moduleStyles.color) {
    updatedStyles.color = moduleStyles.color;
  }

  return (
    <div
      className={classNames(
        moduleStyles.editorialTitle,
        editorialTitleStylesOverrideClass,
        !subcopy && defaultStyles.titleWithNoSubcopy
      )}
      data-context={headline}
      data-is="EditorialTitle"
      style={updatedStyles}
    >
      {label && (
        <span
          className={moduleStyles.label}
          style={{
            backgroundColor: "#fff",
            color: "#000",
            padding: "2px 10px",
          }}
        >
          {label}
        </span>
      )}
      {imageUrl && (
        <div style={{ height: "36px", width: "131px" }}>
          <img
            alt={altText}
            className="img-fluid"
            src={imageUrl}
            title={title}
          />
        </div>
      )}
      <h2
        className={moduleStyles.header}
        data-club-type={clubType}
        style={{
          marginTop: label || headlineMultiline ? "20px" : "0px",
        }}
      >
        {headlineMultiline ? (
          <FormattedHeadline richText={headlineMultiline} />
        ) : (
          <>{headline}</>
        )}
      </h2>

      {children && (
        <div className={styles.editorialSingleImageMobile}>{children}</div>
      )}

      <div
        className={`${moduleStyles.subCopy} d-xl-block d-lg-block d-md-none d-sm-none`}
      >
        {subcopy && <FormattedDescription richText={subcopy} />}
      </div>

      {subcopy && (
        <div
          className={`${moduleStyles.subCopy} d-xl-none d-lg-none d-md-block`}
        >
          <FormattedDescription richText={subcopy} />
        </div>
      )}

      {linkWithArrow && (
        <ul className={moduleStyles.linkList}>
          {linkWithArrow.map((link) => {
            const {
              fields: { isJoinFlow },
            } = link;

            return (
              <li key={link.fields.url}>
                {isJoinFlow ? (
                  <JoinFlowCTA
                    eventSource={LEAD_FLOW_CTA_SOURCE.BODY}
                    isArrow
                    isThemeLight={isThemeLight}
                    label={link.fields.text}
                    originalUrl={link.fields.url}
                  />
                ) : (
                  <LinkWithArrow
                    data={link}
                    isThemeLight={isThemeLight}
                    key={link.fields.url}
                  />
                )}
              </li>
            );
          })}
        </ul>
      )}

      {buttonComp}

      {imageDesktopField && (
        <div
          className={`${moduleStyles.imageWrap} d-lg-block d-md-none d-sm-none`}
        >
          <img
            alt={imageDesktopTitle}
            draggable={false}
            src={imageDesktopField.file.url}
            title={imageDesktopTitle}
          />
        </div>
      )}

      {imageMobileField && (
        <div className={`${moduleStyles.imageWrap} d-md-none`}>
          <img
            alt={imageMobileField.title}
            draggable={false}
            src={imageMobileField.file.url}
          />
        </div>
      )}
    </div>
  );
};

export default EditorialTitle;

EditorialTitle.propTypes = {
  children: PropTypes.object,
  clubType: PropTypes.object,
  data: PropTypes.object,
  isThemeLight: PropTypes.bool,
  styles: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};
